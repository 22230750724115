import { navigate } from 'gatsby'
import React, { ReactElement } from 'react'
import { useAppState } from '../../state/app-context'
import {
  BOTTLE_SELECT_CONTEXT_BACKUP_KEY,
  useBottleSelectDispatch,
  useBottleSelectState,
} from '../../state/bottle-select-context'
import { BottleSelectDispatchType } from '../../types/enums'
import { Page } from '../../types/page'
import IsBrowser from '../../utils/is-browser'
import BottleGroup from '../components/bottle-group'
import PageLayout from '../components/page-layout'
import { Section } from '../elements'
import { PageTitle } from '../grouping'

export default function SelectionScreen({
  availableBottles,
  language,
}: Page.TemplateSelectionPageContext): ReactElement {
  const { appLanguage } = useAppState()
  const { requiresStepTwo, bottles } = useBottleSelectState()
  const dispatch = useBottleSelectDispatch()

  const onBack = () => {
    if (IsBrowser()) {
      window.localStorage.removeItem(BOTTLE_SELECT_CONTEXT_BACKUP_KEY)
    }

    if (requiresStepTwo) {
      dispatch({ type: BottleSelectDispatchType.backToStepOne })
    } else {
      navigate(`/${appLanguage}/`)
    }
  }

  return (
    <PageLayout lang={language} onBack={onBack}>
      <Section className="c__selection-page-template">
        <PageTitle
          subTitle={requiresStepTwo ? 'Step two' : 'Step one'}
          title={
            <>
              Select your
              <br />
              {requiresStepTwo ? 'label' : 'bottle'}
            </>
          }
          alignment="center"
        />
        <BottleGroup language={language} bottles={requiresStepTwo ? bottles : availableBottles} />
      </Section>
    </PageLayout>
  )
}
