import React, { ReactElement } from 'react'
import SelectionScreen from '../components/screens/selection-screen'
import { BottleSelectProvider } from '../state/bottle-select-context'
import { Page } from '../types/page'

export default function SelectionPageTemplate({ pageContext }: Page.TemplateSelectionProperties): ReactElement {
  const { availableBottles, language } = pageContext

  return (
    <BottleSelectProvider>
      <SelectionScreen availableBottles={availableBottles} language={language} />
    </BottleSelectProvider>
  )
}
