import React, { ReactElement, useMemo, useCallback, useState, useEffect } from 'react'
import { useAppState } from '../../state/app-context'
import { useBottleSelectState } from '../../state/bottle-select-context'
import { Component } from '../../types/components'
import { AcceptsChildren, BottleModel } from '../../types/generic'
// import IsMobile from '../../utils/is-mobile'
import { BottleSelect, BottleSelectMultiple } from '../grouping'
import Slider from './slider'
import useMedia from '../../hooks/use-media'

type Bottles = Record<string, { bottle: BottleModel; flagship: BottleModel }>

const DESKTOP_SLIDER_THRESHOLD = 5
const IPAD_SLIDER_THRESHOLD = 3

export default function BottleGroup({ bottles, language }: Component.BottleGroup): ReactElement {
  const [sliderThreshold, setSliderThreshold] = useState(0)
  const isDesktop = useMedia()

  const { region, store } = useAppState()
  const { requiresStepTwo } = useBottleSelectState()
  // const isMobile = useMediaQuery('(max-width: 767px)')

  const filterBottles = useCallback(() => {
    return region === ''
      ? bottles
      : bottles.filter((bottle) => {
          return bottle.regions.includes(region) || (bottle.stores || []).includes(store) || store === 'head_office'
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, store, bottles])

  const regionBottles = useMemo(filterBottles, [filterBottles])

  const bottleGrouping: Bottles = useMemo(() => {
    return regionBottles.reduce((accumulator, current) => {
      const currentBottlesKey = current.isFlagship ? current.flagshipAlternative : current.id
      const currentPropertyKey = current.isFlagship ? 'flagship' : 'bottle'

      return {
        ...accumulator,
        [currentBottlesKey]: {
          ...accumulator[currentBottlesKey],
          [currentPropertyKey]: current,
        },
      }
    }, {} as Bottles)
  }, [regionBottles])

  const renderSlider = useMemo(() => {
    return Object.keys(bottleGrouping).length > sliderThreshold
  }, [bottleGrouping, sliderThreshold])

  useEffect(() => {
    if (isDesktop) {
      setSliderThreshold(DESKTOP_SLIDER_THRESHOLD)
    } else {
      setSliderThreshold(IPAD_SLIDER_THRESHOLD)
    }
  }, [isDesktop])

  const renderBottleSelect = ({ id, image, title, slug, media, comingSoon }: BottleModel) => {
    return (
      <div key={id}>
        <BottleSelect
          language={language}
          image={image}
          title={title}
          id={id}
          slug={slug}
          media={media}
          className="animated animated--fade-in-up"
          comingSoon={comingSoon || false}
        />
      </div>
    )
  }

  const mapBottles = (key: string) => {
    const { bottle, flagship } = bottleGrouping[key]

    if (!bottle && flagship) {
      return renderBottleSelect(flagship)
    }

    if (bottle && flagship) {
      return <BottleSelectMultiple key={bottle.id} primaryBottle={bottle} flagshipBottle={flagship} />
    }

    return renderBottleSelect(bottle)
  }

  const GroupSlider = ({ children }: AcceptsChildren): ReactElement => {
    return (
      <Slider slidesPerView={sliderThreshold} includeDots={false} includeArrows loop spacing={10}>
        {children}
      </Slider>
    )
  }

  const renderBottleGrouping = () => {
    if (renderSlider) {
      return <GroupSlider>{Object.keys(bottleGrouping).map((element) => mapBottles(element))}</GroupSlider>
    }
    return Object.keys(bottleGrouping).map((element) => mapBottles(element))
  }

  const renderRegionBottles = () => {
    if (renderSlider) {
      return <GroupSlider>{regionBottles.map((element) => renderBottleSelect(element))}</GroupSlider>
    }

    return regionBottles.map((element) => renderBottleSelect(element))
  }

  return (
    <div className={`c__bottle-group ${renderSlider ? '' : 'flex justify-space-evenly no-slider'}`}>
      {requiresStepTwo ? renderRegionBottles() : renderBottleGrouping()}
    </div>
  )
}
